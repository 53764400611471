import { useEffect, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { getIsOnpremise } from "../utils";
import getEnv from "../utils/getEnv";
var isOnpremise = getIsOnpremise();
var getWebsocketURL = function getWebsocketURL() {
  var _window;
  if (typeof window === "undefined") {
    return getEnv("NEXT_PUBLIC_WS_URL");
  }
  if (!isOnpremise) {
    return getEnv("NEXT_PUBLIC_WS_URL");
  }
  var hostname = (_window = window) === null || _window === void 0 ? void 0 : _window.location.hostname;
  return "ws://".concat(hostname, ":").concat(getEnv("NEXT_PUBLIC_ONPREMISE_BACKEND_PORT"), "/ws");
};
var useWebsocket = function useWebsocket(urlPath, isEnabled) {
  var _useState = useState(null),
    wsData = _useState[0],
    setWSData = _useState[1];
  var _useState2 = useState(null),
    socket = _useState2[0],
    setSocket = _useState2[1];
  // useful in showing the states for the socket connections
  var _useState3 = useState(false),
    isWSConnectionOpen = _useState3[0],
    setConnectionOpen = _useState3[1];
  var _useState4 = useState(true),
    isWSConnectionLoading = _useState4[0],
    setConnectionLoading = _useState4[1];
  var _useState5 = useState(false),
    isWSConnectionClosed = _useState5[0],
    setConnectionClosed = _useState5[1];
  useEffect(function () {
    if (!isEnabled) {
      return;
    }
    var websocket;
    var connectionTimeout;
    var _connect = function connect() {
      var socketUrl = getWebsocketURL() + urlPath;
      websocket = new W3CWebSocket(socketUrl);
      websocket.onopen = function () {
        setConnectionOpen(true);
        setConnectionLoading(false);
        clearTimeout(connectionTimeout);
        setSocket(websocket);
      };
      websocket.onerror = function () {
        setConnectionOpen(false);
        setConnectionLoading(false);
        websocket.close();
      };
      websocket.onmessage = function (event) {
        if (typeof event.data === "string") {
          var _data = JSON.parse(event.data);
          setWSData(_data);
        }
      };
      websocket.onclose = function (e) {
        if (!isWSConnectionClosed) {
          console.log("Socket is closed. Trying to reconnect...");
          connectionTimeout = setTimeout(function () {
            _connect();
          }, 5000);
        }
      };
    };
    _connect();
    return function () {
      var _websocket;
      if (((_websocket = websocket) === null || _websocket === void 0 ? void 0 : _websocket.readyState) === 1) {
        var _websocket2;
        setConnectionClosed(true);
        setConnectionOpen(false);
        (_websocket2 = websocket) === null || _websocket2 === void 0 || _websocket2.close();
      }
    };
  }, []);
  return {
    socket: socket,
    wsData: wsData,
    isWSConnectionOpen: isWSConnectionOpen,
    isWSConnectionLoading: isWSConnectionLoading
  };
};
export default useWebsocket;